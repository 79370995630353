import React from "react";
import { graphql } from "gatsby";
import Layout2 from "../components/layout2";
import SEO from "../components/seo";
import Img from "gatsby-image";

const Praxis = ({ data }) => {
  const imageData = data.acaImg.childImageSharp.fluid;
  const praxis2Data = data.praxis2Img.childImageSharp.fluid;
  return (
    <Layout2 headerClass="bg-white relative">
      <SEO title="Die Praxis" />

      <div className="min-h-screen pt-24 sm:pt-32 md:pt-64 -mt-12 sm:-mt-16 md:-mt-24 lg:-mt-28 mb-20 bg-size-5/6 md:bg-size-4/5 lg:bg-size-2/3 bg-right-top bg-no-repeat flex flex-col items-center">
        <div className="container w-full mt-8  self-start">
          <h1 className="text-3xl md:text-5xl text-brand3 leading-tight">
            Die Praxis
          </h1>
          <p className="text-xl tracking-wide font-normal mt-8">
            Unser freundliches Praxis Team begrüsst Sie gerne in der modern
            eingerichteten und auf dem neusten Stand der Technik gehalten
            Praxis. Unsere Partner und Lieferanten garantieren die Einhaltung
            unserer hohen Qualitätsanforderungen an Material und
            Dienstleistungen.
            <br />
            <br />2 Parkplätze stehen direkt vor der Praxis zur Verfügung.
          </p>
          <h2 className="w-full text-2xl md:text-3xl text-brand leading-tight mt-16">
            Dr. Aleksandar Stojanović
          </h2>
          <Img fluid={imageData} className="w-full mt-8" />
          <div className="w-full">
            <h3 className="mt-10 mb-6 text-3xl text-brand">
              Dr. med. dent. Stojanović
            </h3>
            <table className="w-full md:w-3/4 text-left w-full border-collapse">
              <tbody>
                <tr className="border-t h-10">
                  <td className="font-semibold pr-4">2018</td>
                  <td>Promotion zum Dr. nach 10 Jahre Forschung</td>
                </tr>
                <tr className="border-t h-10">
                  <td className="font-semibold pr-4">2013</td>
                  <td>Übernahme der Praxis</td>
                </tr>
                <tr className="border-t h-10">
                  <td className="font-semibold pr-4">2012 - 2013</td>
                  <td>Zahnarzt Vollzeit Lifedent AG, Volketswil ZH</td>
                </tr>
                <tr className="border-t h-10">
                  <td className="font-semibold pr-4">2009 - 2013</td>
                  <td>Assistenzzahnarzt Teilzeit, Praxis Toptsis Zürich ZH</td>
                </tr>
                <tr className="border-t h-10">
                  <td className="font-semibold pr-4">2009 - 2011</td>
                  <td>
                    Assistenzzahnarzt Teilzeit, Praxis Nadjenovic und Baran
                    Zürich ZH
                  </td>
                </tr>
                <tr className="border-t h-10 border-b">
                  <td className="font-semibold pr-4">2008</td>
                  <td>Eidg. Examen Zahnmedizin an der Universität Zürich</td>
                </tr>
                <tr className="border-t h-10 border-b">
                  <td className="font-semibold pr-4">2000 - 2008</td>
                  <td>Zahnmedizinstudium an der Universität Zürich</td>
                </tr>
                <tr className="border-t h-10 border-b">
                  <td className="font-semibold pr-4">1994 - 2000</td>
                  <td>Stiftsschule Einsiedeln</td>
                </tr>
                <tr className="border-t h-10 border-b">
                  <td className="font-semibold pr-4">1981</td>
                  <td>Geboren in Einsiedeln</td>
                </tr>
              </tbody>
            </table>
            <Img fluid={praxis2Data} className="w-full mt-8" />
          </div>
        </div>
      </div>
    </Layout2>
  );
};

export default Praxis;

export const query = graphql`
  query {
    acaImg: file(relativePath: { eq: "DrAleksandarStojanovic.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    praxis2Img: file(relativePath: { eq: "door_xxl.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
